export default {
  user: [],
  banners: [],
  sites: [],
  sites_credit: [],
  creditincrese: false,
  creditdecrese: false,
  lastbonus: [],
  alertwithdraw: false,
  promotions: [],
  promotion: [],
  gotpro: false,
  loading: false,
  ref: undefined,
  refsite: undefined,
  games: [],
  hotGames: [],
  newGames: [],
  slotbrands: [],
  casinoGames: [],
  urlplaygame: '',
  minigames: [],
  fishgames: [],
  favoritegames: [],
  cardGames: [],
  rewards: [],
  allgames: [],
  splips: [],
  rewards_category: [],
  allrewards: []
}
