<template>
  <div>
    <headerSection mainMenu="rewards"></headerSection>
    <div id="main__content" class="">

<div class="x-cover x-index-event-container" style="padding-top: 0px;">
            <img src="../assets/images/cover-redeem-bg.png" class="-bg" alt="สูตรสล็อต สูตรบาคาร่า เจ้าแรกในไทย ผ่านเว็บตรง XXMAC">
        <img src="../assets/images/cover-redeem-bg-mobile.png" class="-bg -mobile" alt="สูตรสล็อต สูตรบาคาร่า เจ้าแรกในไทย ผ่านเว็บตรง XXMAC">

        <div class="container-fluid">
            <div class="row -contents-wrapper">
                <div class="col-12 col-md-6 -left-content"></div>
                <div class="col-12 col-md-6 -right-content bg-mobile">
                    <div data-slickable="{&quot;fade&quot;:true,&quot;arrows&quot;:false,&quot;autoplay&quot;:true,&quot;infinite&quot;:true,&quot;pauseOnHover&quot;:false,&quot;adaptiveHeight&quot;:true,&quot;slidesToShow&quot;:1,&quot;autoplaySpeed&quot;:5000}" data-animatable="fadeInUp" data-delay="200" class="slick-initialized slick-slider animated fadeInUp">
                      <div class="slick-list draggable" style="height: 58px;"><div class="slick-track" style="opacity: 1; width: 4495px; top: 5px; display: flex; justify-content: flex-end; float: right;">
                        <div class="slick-slide slick-current slick-active resize bg-mobile2" data-slick-index="0" aria-hidden="false" style="position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;line-height: 1.3;margin-bottom: 10px;">
                                        <h1 class="-title">ของรางวัลเพียบ <br>  จัดหนัก แลกได้ตลอดเวลา</h1>
                                    <p class="-sub-title">สะสมเหรียญเพื่อแลกของรางวัลที่มีได้หมด ทุกเวลา</p>
                        </div></div></div>
                      </div>
                </div>
            </div>
        </div>
    </div>

    <div class="x-event-index-container">
        <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="category-wrapper">
                  <a v-for="(item,index) in category" 
                     :key="index"
                     href="javascript:void(0)" 
                     :class="['btn -btn-category', { active: selectedCategory === item }]"
                     @click="selectCategory(item)">
                    {{item}}
                  </a>
                </div>
              </div>
            </div>
            <div class="row -event-row-wrapper">
                <div class="col-lg-4 col-6 -col-wrapper" v-for="(item,index) in rewards">
                    <div class="-card-item animated fadeInUp" data-animatable="fadeInUp" data-delay="200">
                        <a class="d-block h-100 -card-link-wrapper">
                            <div class="x-card card -multi-card">
                                <div class="-img-container">
                                    <img :src="item.image" :alt="item.name" class="-img">
                                </div>
                                <div class="card-body">
                                    <div class="-title-container m-3">
                                        <h3 class="-title">{{item.name}}</h3>
                                        <div data-v-d9ae3272="" class="-deposit-container d-xl-block">
                                            <a data-v-d9ae3272="" class="text-white js-account-approve-aware btn -btn-redeem" :disabled="(!user || !user.key || !user.coin || Number(user.coin)<item.point)?true:false" @click="redeem(item)">
                                              <div data-v-d9ae3272="" class="f-7">แลกทันที</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div id="account-actions" class="-logged">
    <div class="x-button-actions" id="account-actions-mobile">

<!-- <div class="d-flex">
    <div class="-btn-container -left">
        <a href="javascript:void(0);" data-toggle="modal" data-target="#withdrawModal" class="btn btn-lg btn-plain-primary  js-account-approve-aware">
            <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_withdraw_mobile.png" alt="XXMAC ถอนเงิน" class="-icon img-fluid">
            <div class="-typo">ถอนเงิน</div>
        </a>
    </div>
    <div class="-btn-container -center">
        <a href="/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95" class="btn btn-lg btn-plain-lobby">
            <div class="-img-container">
                <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_lobby_mobile.png" class="-icon img-fluid" alt="XXMAC เข้าเล่น Lobby">
                <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_lobby_mobile_play.png" class="-icon-play img-fluid" alt="XXMAC เข้าเล่น Lobby">
            </div>
            <div class="-typo">หน้าเกมส์</div>
        </a>
    </div>
    <div class="-btn-container -right">
        <a href="javascript:void(0);" class="btn btn-lg btn-plain-secondary js-account-approve-aware" data-toggle="modal" data-target="#depositModal">
            <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_deposit_mobile.png" alt="XXMAC ฝากเงิน" class="-icon img-fluid">
            <div class="-typo">ฝากเงิน</div>
        </a>
    </div>
</div> -->
            </div>
</div>

<footerSection></footerSection>

            </div>

</div>
</template>

<script>
import firebase from 'firebase/app'
import moment from 'moment'
const axios = require('axios').default
import _ from 'lodash'
export default {
  name: 'Home',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  mounted () {
    // Bonn.boots.push(function() {
    //   setTimeout(function() {
    //     $('#bankInfoModal').modal('show');
    //   }, 500);
    // });
    this.$store.dispatch('user/getRewards').catch(err => { console.error(err) })
    // this.checkAnoucement()
  },
  computed: {
    sites_credit () {
      return this.$store.state.user.sites_credit
    },
    user () {
      return this.$store.state.user.user
    },
    rewards () {
      return this.$store.state.user.rewards
    },
    allrewards () {
      return this.$store.state.user.allrewards
    },
    rewards_category () {
      return this.$store.state.user.rewards_category
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    lastbonus () {
      return this.$store.state.user.lastbonus
    }
  },
  data () {
    return {
      continueCheck: false,
      receiveAnounceMent: false,
      anoucement: {},
      balance: 0,
      fa_spin: false,
      category: ['แลกเครดิต', 'สุขภาพ ความงาม', 'แฟชั่น อินเทรนต์', 'บ้าน ไลพ์สไตล์', 'อาหาร เครื่องดื่ม'],
      selectedCategory: 'แลกเครดิต',
    }
  },
  methods: {
    async conFirmRedeem (item) {
        this.$confirm(
          {
            message: `ต้องการแลกของรางวัล ${item.name}`,
            button: {
              yes: 'ยินยัน',
              no: 'ยกเลิก'
            },
            callback: confirm => {
              if (confirm) {
                this.$store.commit('user/SET_LOADING', true)
                setTimeout(() => {
                  firebase.firestore().collection('redeem').add({
                    userkey: this.user.key,
                    code: this.user.code,
                    reward: item.key,
                    type: item.type,
                    credit: (item.credit) ? item.credit : '',
                    point: item.point,
                    status: false,
                    createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
                  }).then((result) => {
                    axios({
                      method: 'post',
                      url: 'https://api.xxmac.co/qmovetowallet/redeem',
                      data: {
                        code: this.user.code,
                        key: result.id
                      }
                    })
                    this.$store.commit('user/SET_LOADING', false)
                  })
                }, Math.floor(Math.random() * 3000))
              }
            }
          }
        )
    },
    async redeem (item) {
      if (!(!this.user || !this.user.key || !this.user.coin || Number(this.user.coin) < item.point)){
        if (item.type === 'credit') {
          this.$store.dispatch('user/refreshCreditAll').finally(res => {
            if (this.sites_credit.credit >= 5) {
              this.$swal({ icon: 'error', title: 'แลกเครดิต', html: 'ไม่สามารถแลกเครดิตได้เนื่องจากมียอดคงเหลือมากกว่า 5 บาท' })
            } else {
              this.conFirmRedeem(item)
            }
          })
        } else {
          this.conFirmRedeem(item)
        }
      }
    },
    selectCategory(category) {
      this.selectedCategory = category;
      const rewards = this.allrewards.filter(item => item.category === category)
      this.$store.commit('user/SET_REWARDS', _.orderBy(rewards, ['point'], ['asc']))
    }
  }
}
</script>
<style scoped>
@media screen and (max-width: 992px) {
    .resize {
        width: 899px;
    }
}
@media screen and (max-width: 600px) {
    .resize {
        width: 399px;
    }
    .bg-mobile{
      background-color: #1c1c1c;
    }
    .bg-mobile2{
      margin-right: 0;
    }
}
.btn {
    border-radius: 25px;
    color: #fff;
    border: none;
    padding: 0.8rem 2.5rem;
}
.btn.-btn-redeem{background:radial-gradient(100% 100% at 50% 0,#a8ff9c 0,#4dd842 100%);box-shadow:0 2px 4px rgba(0,0,0,.12),0 1px 2px hsla(0,7.3%,92%,.24)}
.btn.-btn-redeem:hover{background:radial-gradient(100% 100% at 50% 0,#a8ff9b 0,#33bf28 100%)}
.btn.-btn-redeem:disabled,
.btn.-btn-redeem[disabled]{background:radial-gradient(100% 100% at 50% 0,#cccccc 0,#9e9999 100%)}

.category-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
  justify-content: center;
}

.-btn-category {
  background: linear-gradient(45deg, #2b2b2b, #1a1a1a);
  color: #fff;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 14px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255,255,255,0.1);
}

.-btn-category.active {
  background: linear-gradient(45deg, #4dd842, #a8ff9c);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(77, 216, 66, 0.2);
}

.-btn-category:hover {
  background: linear-gradient(45deg, #4dd842, #a8ff9c);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(77, 216, 66, 0.2);
}

@media screen and (max-width: 600px) {
  .category-wrapper {
    gap: 8px;
  }
  
  .-btn-category {
    font-size: 12px;
    padding: 6px 15px;
  }
}
</style>
